import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, fontSizes, lineHeights, colors, radius } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Flex, Grid, Box } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import ExploreFullPlatform from 'components/GetStartedComponent'
import Button from 'components/Button'
import Image from 'components/LocalImage'
import Calendly from 'components/Calendly'

import imageHeader from 'static/images/solutions_multi_location_brands/header_picture.webp'

import imageRevenue from 'static/images/solutions_multi_location_brands/revenue.svg'
import imageBranding from 'static/images/solutions_multi_location_brands/branding.svg'
import imageWorkflow from 'static/images/solutions_multi_location_brands/workflow.svg'

import imageInfrastructure from 'static/images/solutions_multi_location_brands/image_infrastructure.webp'
import imageDataPlatform from 'static/images/solutions_multi_location_brands/image_data_platform.webp'

import PoweredBy from 'components/PoweredByAI'
import TrialTerms from 'components/TrialTerms'
import Awards from 'components/Awards'

import RequestDemoButton from '../../Home/components/RequestDemoButton'
import SelectPlanButton from '../../Home/components/SelectPlanButton'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const TrialButton = styled(Button.Gradient)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
  margin-right: 10px;
`

const StyledScheduleDemoButton = styled(Button.Reversed)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
`

const SOLUTION_OPTIONS = [
  { image: imageRevenue, title: 'Increase Revenue With Enhanced Visibility' },
  { image: imageBranding, title: 'Reduce Risk With Consistent Branding' },
  { image: imageWorkflow, title: 'Lower Costs with Streamlined Workflows' },
]

const INFRASTRUCTURE_ITEMS = [
  {
    title: 'Connecting All Networks for All Locations',
    description:
      'Connect all social social networks, search, and review channels into a single infrastructure, enabling seamless communication and efficient management.',
  },
  {
    title: 'Tiered Access & Permissions',
    description:
      'Local, regional, and enterprise-level access with necessary controls and protections at each tier. This ensures workflows are optimized while brand integrity is upheld.',
  },
  {
    title: 'Asset Management & Workflows',
    description:
      'Store, organize, share, and monitor content and best practices easily. Streamline user workflows and approval processes for enhanced efficiency and consistency.',
  },
]

const DATA_PLATFORM_ITEMS = [
  {
    title: 'Unify Data from Every Location',
    description:
      'Eliminates data silos by integrating real-time data from all major local channels and enterprise backend systems.',
  },
  {
    title: 'Harmonize Data',
    description: 'Cleans and enriches your dataset for precise, AI-driven decisions.',
  },
  {
    title: 'Data Security & Compliance',
    description: 'Prioritizes data security and legal regulations to safeguard your information.',
  },
]

const SolutionsMultiLocationBrands = () => {
  return (
    <App fullHeader>
      <SEO
        title="Effectively manage social media for multi-Location brands | Vista Social"
        description="Create content across your locations, ensure consistent brand voice and style. Vista Social social media tools will ensure you get your work done quickly and efficiently. Gain insights necessary to understand the impact of your marketing efforts."
        path="/multi-location-brands/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
              position="relative"
            >
              <Flex width="100%" justifyContent="center" flexDirection="column">
                <PoweredBy message="Purpose Built for Multi-Location Enterprises" />
                <Flex mt="m" flexDirection="column" maxWidth={{ mobile: '100%', desktop: '495px' }}>
                  <H1
                    fontSize="xxxl"
                    fontWeight="bold"
                    color={COLOR_CONSTANTS.DENIM}
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                  >
                    Effectively manage social media for{' '}
                    <H1
                      as="span"
                      fontSize="xxxl"
                      fontWeight="bold"
                      color="primary"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                    >
                      multi-location brands
                    </H1>
                  </H1>
                  <Flex mt="m" pt="s">
                    <H2
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      Create content across multiple business locations, and ensure consistent brand voice and style.
                      Vista Social's social media management tools will ensure you get your work done quickly and
                      efficiently. Gain insights necessary to understand the impact of your marketing efforts.
                    </H2>
                  </Flex>

                  <TrialTerms />
                  <Flex
                    mt="l"
                    alignItems="center"
                    justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                    flexDirection={{ mobile: 'column', mobileLarge: 'row' }}
                  >
                    <SelectPlanButton />

                    <Flex ml={{ mobile: 0, mobileLarge: 'm' }}>
                      <RequestDemoButton />
                    </Flex>
                  </Flex>
                  <Awards />
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="500px"
                  alt="Effectively manage social media for multi-location srands"
                />
              </Flex>
            </Grid>

            <Flex mt={{ mobile: 'l', tablet: 'xl' }} pt="m" flexDirection="column" alignItems="center">
              <G2FeaturesComponent />
            </Flex>

            <Grid
              mt={{ mobile: 'l', tablet: 'xl' }}
              gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(3, 1fr)' }}
              gridGap="l"
            >
              {SOLUTION_OPTIONS.map(({ title, image }) => {
                return (
                  <Flex flexDirection="column" key={title} p="xm" borderRadius={radius.xl} bg="primary">
                    <Image src={image} width="48px" height="48px" alt={title} />
                    <Text textAlign="left" mt="l" pt="xs" color="white" fontWeight="medium" fontSize="xxl">
                      {title}
                    </Text>
                  </Flex>
                )
              })}
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="545px" width="100%">
              <LocalImage src={imageInfrastructure} width="100%" alt="Multi-Location Infrastructure" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 1 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
            >
              <Flex flexDirection="column" maxWidth={{ mobile: '100%', desktop: '465px' }}>
                <Text
                  fontWeight="bold"
                  fontSize="3xl"
                  color={COLOR_CONSTANTS.DENIM}
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                >
                  Multi-Location Infrastructure
                </Text>

                <Text mt="s" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  The foundation of our platform is specifically engineered to support multi-location enterprises and
                  their unique workflows.
                </Text>
              </Flex>

              <Grid
                mt="l"
                pt="m"
                gridGap="40px"
                gridTemplateColumns="repeat(1, 1fr)"
                maxWidth={{ mobile: '100%', desktop: '511px' }}
              >
                {INFRASTRUCTURE_ITEMS.map(({ title, description }) => (
                  <Flex key={title}>
                    <Flex
                      width="8px"
                      bg={colors.primary}
                      minWidth="8px"
                      borderRadius={`0px ${radius.l} ${radius.l} 0px`}
                    />
                    <Flex ml="m" flexDirection="column">
                      <Text fontWeight="bold" fontSize="l" textAlign="left" color={COLOR_CONSTANTS.DENIM}>
                        {title}
                      </Text>
                      <Text mt="s" fontSize="m" color="secondaryText" textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          pb={{ mobile: 'l', tablet: 'xxl' }}
          pt="0"
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 1 }} maxWidth="545px" width="100%">
              <LocalImage src={imageDataPlatform} width="100%" alt="Data Platform" />
            </Flex>
            <Flex
              order={{ mobile: 1, desktop: 2 }}
              flexDirection="column"
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
            >
              <Flex flexDirection="column" maxWidth={{ mobile: '100%', desktop: '465px' }}>
                <Text
                  fontWeight="bold"
                  fontSize="3xl"
                  color={COLOR_CONSTANTS.DENIM}
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                >
                  Data Platform
                </Text>

                <Text mt="s" color="secondaryText" fontSize="l" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  The centralized foundation of our platform, our Data Platform powers workflows, actions, and
                  generative AI across all applications.
                </Text>
              </Flex>

              <Grid
                mt="l"
                pt="m"
                gridGap="40px"
                gridTemplateColumns="repeat(1, 1fr)"
                maxWidth={{ mobile: '100%', desktop: '511px' }}
              >
                {DATA_PLATFORM_ITEMS.map(({ title, description }) => (
                  <Flex key={title}>
                    <Flex
                      width="8px"
                      bg={colors.primary}
                      minWidth="8px"
                      borderRadius={`0px ${radius.l} ${radius.l} 0px`}
                    />
                    <Flex ml="m" flexDirection="column">
                      <Text fontWeight="bold" fontSize="l" textAlign="left" color={COLOR_CONSTANTS.DENIM}>
                        {title}
                      </Text>
                      <Text mt="s" fontSize="m" color="secondaryText" textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          pt={{ mobile: 'l', tablet: 'xxl' }}
          pb="0"
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Achieve Market-Leading Results With{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Vista Social
            </H3>
          </H3>

          <Flex px="m" alignItems="center" flexDirection="column" textAlign="center">
            <Box width="100%" minHeight="920px">
              <Calendly link="https://calendly.com/d/4d7-x9f-r7z" />
            </Box>
          </Flex>
        </Container>
      </Wrapper>

      {/* <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.enterprise} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper> */}

      <ExploreFullPlatform />

      <Footer />
    </App>
  )
}

export default SolutionsMultiLocationBrands
